import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import * as styles from './header.module.css';
import LinkedInIcon from '../../images/linkedin-icon.inline.svg';
import logoSmall from '../../images/logo-small-red.png';
import logoSmall2x from '../../images/logo-small-red@2x.png';
import RssIcon from '../../images/rss-icon.inline.svg';
import tomekDevLogoText from '../../images/tomek-dev.svg';
import TwitterIcon from '../../images/twitter-icon.inline.svg';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuExpanded: false,
    };

    this._toggleMenuExpanded = this._toggleMenuExpanded.bind(this);
  }

  _toggleMenuExpanded() {
    const { isMenuExpanded } = this.state;
    this.setState({
      isMenuExpanded: !isMenuExpanded,
    });
  }

  render() {
    const { isMenuExpanded } = this.state;
    const { solidBottom } = this.props;

    return (
      <header
        className={`${styles.header} ${solidBottom ? styles.solidBottom : ''}`}
      >
        <div className="container">
          <div className={styles.headerInner}>
            <Link to="/" className={styles.logoTextLink} aria-label="Homepage">
              <img
                src={logoSmall}
                srcSet={`${logoSmall} 1x, ${logoSmall2x} 2x`}
                className={styles.logoSmall}
                alt="&#9661;"
                width="46"
                height="45"
              />
              <img
                src={tomekDevLogoText}
                className={styles.tomekDevLogoText}
                alt="TOMEK DEV"
                width="136"
                height="20"
              />
            </Link>
            <label
              htmlFor="mobile-menu-state"
              className={`${styles.mobileMenuTrigger} ${
                isMenuExpanded ? styles.open : ''
              }`}
              onClick={this._toggleMenuExpanded}
            >
              <div className={styles.hamburgerIcon}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </div>
              <div>Menu</div>
            </label>
            <input
              id="mobile-menu-state"
              type="checkbox"
              className={styles.mobileMenuState}
            />
            <div className={styles.menuAndSocials}>
              <nav className={styles.menu}>
                <Link
                  to="/"
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                  partiallyActive={(() => {
                    if (typeof window !== 'undefined') {
                      const pathName = window.location.pathname;
                      return pathName === '/' || pathName.includes('/posts/');
                    }
                  })()}
                >
                  <span>Blog</span>
                </Link>
                <Link
                  to="/about-me"
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                >
                  <span>About me</span>
                </Link>
                <Link
                  to="/til"
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                >
                  <span>TIL!</span>
                </Link>
                <Link
                  to="/sideprojects"
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                >
                  <span>Sideprojects</span>
                </Link>
                <Link
                  to="/tools"
                  className={styles.menuLink}
                  activeClassName={styles.menuLinkActive}
                  partiallyActive={(() => {
                    if (typeof window !== 'undefined') {
                      const pathName = window.location.pathname;
                      return pathName.includes('/tools');
                    }
                  })()}
                >
                  <span>Tools</span>
                </Link>
              </nav>
              <div className={styles.socialButtons}>
                <a
                  href="https://twitter.com/tomekdev_"
                  className={styles.socialButton}
                  rel="noreferrer"
                  target="_blank"
                  aria-label="tomekdev_ twitter account"
                >
                  <TwitterIcon className={styles.socialIcon} />
                </a>
                <a
                  href="https://www.linkedin.com/in/tomaszniezurawski/"
                  className={`${styles.socialButton} ${styles.socialButtonLn}`}
                  rel="noreferrer"
                  target="_blank"
                  aria-label="Tomasz Nieżurawski LinkedIn profile"
                >
                  <LinkedInIcon className={styles.socialIcon} />
                </a>
                <a
                  href="/rss.xml"
                  className={styles.socialButton}
                  rel="noreferrer"
                  target="_blank"
                  aria-label="Blog RSS"
                >
                  <RssIcon className={styles.socialIcon} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

Header.defaultProps = {
  solidBottom: false,
};

Header.propTypes = {
  solidBottom: PropTypes.bool,
};

export default Header;
