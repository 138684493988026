// extracted by mini-css-extract-plugin
export var copy = "footer-module--copy--2nW3W";
export var fBreak = "footer-module--fBreak--L_rFe";
export var footer = "footer-module--footer--2jjd0";
export var footerInner = "footer-module--footerInner--3_xC3";
export var greetingsBox = "footer-module--greetingsBox--q1jLe";
export var greetingsBoxImage = "footer-module--greetingsBoxImage--143zH";
export var greetingsBoxImageWrapper = "footer-module--greetingsBoxImageWrapper--IJI2q";
export var greetingsBoxText = "footer-module--greetingsBoxText--3QZva";
export var logoAndCopy = "footer-module--logoAndCopy--Us0TK";
export var menu = "footer-module--menu--35vZ_";
export var menuLink = "footer-module--menuLink--1iVdy";
export var smallTriangle = "footer-module--smallTriangle--2QruM";